// basic stuff
import React, { useEffect, useState, useCallback, useRef } from "react"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useMutation, useQuery } from "react-query"
import { navigate } from "gatsby"
import produce from "immer"
import { isMobileSafari, isMobile } from "react-device-detect"
import { ToastContainer, toast } from "react-toastify"

// components
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Layout from "../../../components/layout"
import ProfileLayout from "../../../components/UserProfileLayout"
import Seo from "../../../components/seo"
import Tali from "../../../components/Tali/TaliContainer.js"
import {
  TextInput,
  NumberInput,
} from "../../../components/UserProfileFormBlocks"
import PasswordInput from "../../../components/UserProfileFormBlocks/PasswordInput.js"
import { handleJWTtimeout } from "../../../components/handleJWTtimeout.js"

// global styles
import { MainButton } from "../../../styles/buttons.js"
import { createColorGradient } from "../../../styles/helpers"
import { theme } from "../../../styles/theme"
import { Tooltip } from "../../../styles/common"

// icons
import PlusIcon from "../../../lib/icons/Plus.js"

// graphQl
import { useGraphqlClientStore, useTeacherStore } from "../../../../store"
import {
  CURRENT_USER_QUERY,
  TEACHER_BY_ID_QUERY,
  UPDATE_TEACHER_BY_ID_MUTATION,
  UPDATE_TEACHER_PASSWORD_BY_ID_MUTATION,
  DELETE_TEACHER_BY_ID_MUTATION,
  CREATE_CLASS_MUTATION,
  UPDATE_CLASS_BY_ID_MUTATION,
  DELETE_CLASS_BY_ID_MUTATION,
  UPDATE_SCHOOL_NAME_BY_ID_MUTATION,
  CREATE_STUDENT_MUTATION,
  UPDATE_STUDENT_BY_ID_MUTATION,
  DELETE_STUDENT_BY_ID_MUTATION,
  WORD_QUERY,
  WORD_FAMILY_QUERY,
} from "../../../graphql_requests"

// hooks
import { useSSRStorage } from "../../../hooks/index.js"

// helpers
import {
  isJWTExpired,
  TeacherActivity,
  ChangeSettingsActivity,
} from "../../../services/helpers"

// constants
import {
  DEFAULT_MISTAKE_PROGNOSIS,
  GRAPHEMES_DEFAULT,
  UI_EVENT_NAMES,
} from "../../../const_values"

const { ALL, TEACHER_SETTINGS } = UI_EVENT_NAMES

//local styles
const StyledActionButton = styled(MainButton)`
  ${createColorGradient("#FBCA82", "#FF9766", "to right")}
  width: min-content;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6vw + 12px);
  position: relative;
`

const EditButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      title={props.title}
    >
      {props.tooltipText && (
        <Tooltip className={props.position}>{props.tooltipText}</Tooltip>
      )}
      ✏️
    </StyledActionButton>
  )
}

const DeleteButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      onKeyDown={props.handleKeyPress}
      title={props.title}
    >
      {props.tooltipText && (
        <Tooltip className={props.position}>{props.tooltipText}</Tooltip>
      )}
      ⛔
    </StyledActionButton>
  )
}

const DashboardButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={{
        fontSize: "calc(0.6vw + 16px)",
        ...props.style,
      }}
      onClick={props.onClick}
    >
      {props.tooltipText && (
        <Tooltip className={props.position}>{props.tooltipText}</Tooltip>
      )}
      📰
    </StyledActionButton>
  )
}

const PDFDownloadButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      title="Schüler*innen-Liste als PDF herunterladen."
    >
      {props.tooltipText && (
        <Tooltip className={props.position}>{props.tooltipText}</Tooltip>
      )}
      📥
    </StyledActionButton>
  )
}

const AddButton = (props) => {
  return (
    <StyledActionButton
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.tooltipText && <Tooltip>{props.tooltipText}</Tooltip>}
      <PlusIcon />
    </StyledActionButton>
  )
}

// main component
const ProfilePage = () => {
  // check if executing instance is the browser or the building process
  const isBrowser = typeof window !== "undefined"

  // jwt from local storage
  const [jwt] = useSSRStorage("jwt_teacher")

  const { width, height } = useWindowSize()

  // states
  const [urlPath, setUrlPath] = useState(
    isBrowser ? window.location.pathname : null
  )
  const [teacherId, setTeacherId] = useState("")
  const [teacherEditMode, setTeacherEditMode] = useState(false)
  const [currentClassEdit, setCurrentClassEdit] = useState(null)
  const [currentSchoolEdit, setCurrentSchoolEdit] = useState(null)
  const [currentClassEditSchool, setCurrentClassEditSchool] = useState(null)
  const [creatingNewClass, setCreatingNewClass] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [globalBeginnerLvl, setGlobalBeginnerLvl] = useState()
  const [globalDailyTarget, setGlobalDailyTarget] = useState(15)
  const [classGraphemesDraft, setClassGraphemesDraft] =
    useState(GRAPHEMES_DEFAULT)
  const [studentGraphemesDraft, setStudentGraphemesDraft] =
    useState(GRAPHEMES_DEFAULT)
  const [classPrioWordDraft, setClassPrioWordDraft] = useState([])
  const [studentPrioWordDraft, setStudentPrioWordDraft] = useState([])
  const [globalSelectionAllowed, setGlobalSelectionAllowed] = useState(false)
  const [classGraphemeSelectOpen, setClassGraphemeSelectOpen] = useState(false)
  const [studentGraphemeSelectOpen, setStudentGraphemeSelectOpen] =
    useState(false)
  const [classPrioWordSelectOpen, setClassPrioWordSelectOpen] = useState(false)
  const [studentPrioWordSelectOpen, setStudentPrioWordSelectOpen] =
    useState(false)
  const [classGraphemeSelectPayload, setClassGraphemeSelectPayload] = useState({
    schoolId: "",
    classId: "",
  })
  const [studentGraphemeSelectPayload, setStudentGraphemeSelectPayload] =
    useState({ schoolId: "", classId: "", studentId: "", studentName: "" })
  const [classPrioWordSelectPayload, setClassPrioWordSelectPayload] = useState({
    schoolId: "",
    classId: "",
  })
  const [studentPrioWordSelectPayload, setStudentPrioWordSelectPayload] =
    useState({ schoolId: "", classId: "", studentId: "", studentName: "" })
  const [classPrioWordInput, setClassPrioWordInput] = useState("")
  const [studentPrioWordInput, setStudentPrioWordInput] = useState("")
  const [prioWordsInput, setPrioWordsInput] = useState("")

  const willLogout = useRef(false)

  const { graphqlClient, resetGraphqlClient } = useGraphqlClientStore(
    (store) => store
  )
  const {
    teacher,
    updateStore,
    updateTeacher,
    updateSchool,
    addClassToSchool,
    updateClass,
    updateAllStudents,
    removeClass,
    addStudentToClass,
    updateStudent,
    updateGraphemeSelection,
    removeStudent,
  } = useTeacherStore((store) => store)

  useQuery(
    ["currentUserIdQuery"],
    () => graphqlClient.request(CURRENT_USER_QUERY),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log(data)
        if (data.currentUserId) {
          setTeacherId(data.currentUserId)
          console.log("login success!")
          console.log({ data })
        }
      },
      onError: (error) => {
        handleJWTtimeout(error)
      },
    }
  )

  useQuery(
    ["teacherByIdQuery", teacherId],
    () => graphqlClient.request(TEACHER_BY_ID_QUERY, { id: teacherId }),
    {
      enabled: !!teacherId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onSuccess: async (data) => {
        console.log("teacher:", data)
        data.teacherById.schoolsByContact.nodes.forEach((school) => {
          school.classesBySchool.nodes = school.classesBySchool.nodes.map(
            (schoolClass) => {
              return { ...schoolClass, activeTab: 0 }
            }
          )
        })

        // setTeacher(data.teacherById)
        updateStore("teacher", data.teacherById)

        try {
          const visitActivity = new TeacherActivity(
            urlPath,
            ALL.PAGE_VISITED,
            teacherId
          )
          await visitActivity.writeToDB()
        } catch (e) {
          console.error("failed to log page visiting: ", e)
        }
      },
    }
  )

  const logPageLeaving = async (event) => {
    try {
      const leaveActivity = new TeacherActivity(
        urlPath,
        ALL.PAGE_LEAVED,
        useTeacherStore.getState().teacher.id
      )
      console.log("teacher on leave: ", teacher)
      await leaveActivity.writeToDB()
    } catch (e) {
      console.error("failed to log page leaving: ", e)
    }

    if (event) {
      event.returnValue = ""
    }

    return ""
  }

  const updateTeacherMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_TEACHER_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateTeacherPasswordMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_TEACHER_PASSWORD_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteTeacherMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_TEACHER_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteStudentMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_STUDENT_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const deleteClassMutation = useMutation(
    (variables) =>
      graphqlClient.request(DELETE_CLASS_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateClassMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_CLASS_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const updateStudentMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_STUDENT_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => console.log(data),
      onError: (error) => console.error(error),
    }
  )

  const updateSchoolNameMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_SCHOOL_NAME_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const createClassMutation = useMutation(
    (variables) => graphqlClient.request(CREATE_CLASS_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error("Error on create class mutation:", error)
      },
    }
  )

  const createStudentMutation = useMutation(
    (variables) => graphqlClient.request(CREATE_STUDENT_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const exportStudents = (schoolId, classId) => {
    const unit = "pt"
    const size = "A4"
    const orientation = "portrait"
    const marginLeft = 40
    const title = "Meine Klasse"
    const headers = [["Name", "Alias", "Zugangscode", "QR-Code"]]
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    const schoolClass = school.classesBySchool.nodes.find(
      (c) => c.id === classId
    )
    const students = schoolClass.studentsByClass.nodes.map((student) => [
      student.name,
      student.alias,
      student.login,
    ])

    const pdfTableConfig = {
      theme: "grid",
      headStyles: {
        minCellHeight: 0,
        fontSize: 12,
        fillColor: theme.colors.primary.main,
        lineWidth: 1,
        lineColor: 0,
      },
      bodyStyles: {
        fontSize: 16,
        minCellHeight: 85,
        minCellWidth: 75,
        valign: "middle",
        cellWidth: "auto",
        lineWidth: 1,
        lineColor: 0,
      },
      startY: 60,
      pageBreak: "auto",
      rowPageBreak: "avoid",
      head: headers,
      body: students,
      didDrawCell: (data) => {
        if (data.column.index === 3 && data.cell.section === "body") {
          const qrCodeSize = data.cell.height - 4
          const qrCodeSrc = `https://chart.googleapis.com/chart?cht=qr&chs=${qrCodeSize}x${qrCodeSize}&chl=app.talidu.de/student/profile?login=${
            students[data.row.index][2]
          }`
          const qrCodePositionX =
            data.cell.x + Math.floor(data.cell.width / 2 - qrCodeSize / 2)
          const qrCodePositionY =
            data.cell.y + Math.floor(data.cell.height / 2 - qrCodeSize / 2)
          doc.addImage(
            qrCodeSrc,
            "JPEG",
            qrCodePositionX,
            qrCodePositionY,
            qrCodeSize,
            qrCodeSize
          )
        }
      },
    }

    const doc = new jsPDF(orientation, unit, size)

    doc.text(title + `: ${schoolClass.name}`, marginLeft, 40)
    doc.autoTable(pdfTableConfig)
    doc.save(`Schüler_innen-Liste ${schoolClass.name}.pdf`)
  }

  const setActiveClassTab = (schoolId, classId, tab) => {
    updateClass(schoolId, classId, "activeTab", tab)
  }

  const handleChange = (e) => {
    switch (e.target.id) {
      case "teacher-name":
        updateTeacher("name", e.target.value)
        break
      case "teacher-email":
        updateTeacher("email", e.target.value)
        break
      case "teacher-password":
        updateTeacher("password", e.target.value)
        break
      default:
        break
    }
  }

  const handleTeacherEditKeypress = (e) => {
    if (e.key !== "Tab") {
      // e.preventDefault()
      handleChange(e)
      if (e.key === "Enter") {
        setTeacherEditMode(false)
      }
    }
  }

  const handleClassEditKeypress = ({ e, schoolId, classId }) => {
    if (e.key !== "Tab") {
      if (e.key === "Enter") {
        e.preventDefault()
        handleChangeClassSave({ schoolId, classId })
      }
    } else {
      e.preventDefault()
      let inputElements = "#class-name,#student-name"

      if (document.activeElement && document.activeElement.form) {
        var focussable = Array.prototype.filter.call(
          document.querySelectorAll(inputElements),
          function (element) {
            //check for visibility while always include the current activeElement
            return (
              element.offsetWidth > 0 ||
              element.offsetHeight > 0 ||
              element === document.activeElement
            )
          }
        )
        var index = focussable.indexOf(document.activeElement)
        if (index > -1) {
          var nextElement = focussable[index + 1] || focussable[0]
          nextElement.focus()
        }
      }
    }
  }

  const handleSave = (e) => {
    e.preventDefault()
    const name = e.target["teacher-name"].value
    const email = e.target["teacher-email"].value
    updateTeacherMutation.mutate({ name, email, id: teacherId })

    const password = e.target["teacher-password"].value
    if (password.length > 0) {
      updateTeacherPasswordMutation.mutate({ password, id: teacherId })
    }
  }

  const deleteAccount = () => {
    const confirmed = isBrowser
      ? window.confirm(
          "🧨 💣 ☠️ \n\nMöchten Sie wirklich Ihr Profil und die Verknüpfung zu allen Ihren Klassen und Schülern löschen?"
        )
      : false

    if (confirmed) {
      console.log("delete!")
      deleteTeacherMutation.mutate({ id: teacherId })
      logout()
    }
  }

  const logout = async () => {
    console.log("logout!")
    willLogout.current = true

    if (isBrowser) {
      window.removeEventListener(
        isMobileSafari ? "pagehide" : "beforeunload",
        logPageLeaving
      )
      window.localStorage.removeItem("jwt_teacher")
    }
    await logPageLeaving()
    resetGraphqlClient()
    navigate("/")
  }

  const handleStudentsAdd = async (schoolId, classId) => {
    console.log("add students", { schoolId }, { classId })
    let studentCount = prompt("Wie viele Schüler möchten Sie hinzufügen", 10)
    if (
      studentCount != null &&
      !isNaN(parseInt(studentCount, 10)) &&
      parseInt(studentCount, 10) > 0
    ) {
      const school = teacher.schoolsByContact.nodes.find(
        (school) => school.id === schoolId
      )
      const schoolClass = school.classesBySchool.nodes.find(
        (schoolClass) => schoolClass.id === classId
      )

      console.log("add ", studentCount, "students")

      for (let i = 0; i < studentCount; i = i + 1) {
        try {
          const createStudentResult = await createStudentMutation.mutateAsync({
            class: classId,
            proficiency: schoolClass.year,
            mistakePrognosis: DEFAULT_MISTAKE_PROGNOSIS,
          })
          const student = createStudentResult.createStudent.student

          // if (!student.proficiency) {
          //   updateStudentProficiencyMutation.mutate({
          //     id: student.id,
          //     proficiency: schoolClass.year,
          //   })
          // }

          // if (!student.mistakePrognosis) {
          //   updateStudentMistakePrognosisMutation.mutate({
          //     id: student.id,
          //     mistakePrognosis: DEFAULT_MISTAKE_PROGNOSIS,
          //   })
          // }

          addStudentToClass(schoolId, classId, student)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const handleClassAdd = async (schoolId) => {
    console.log("add class", { schoolId })

    try {
      const createClass = await createClassMutation.mutateAsync({
        school: schoolId,
        name: "",
        year: 1,
      })
      const newClass = createClass.createClass.class
      const newClassId = newClass.id

      console.log("new class id:", newClassId)
      setCreatingNewClass(true)
      addClassToSchool(schoolId, { ...newClass, activeTab: 0 })
      setCurrentClassEdit(newClassId)
    } catch (error) {
      console.error("Error on class add:", error)
    }
  }

  const handleClassEdit = ({ classId, schoolId }) => {
    console.log("edit class", { classId })
    setCurrentClassEditSchool(schoolId)
    setCurrentClassEdit(classId)
  }

  const handleChangeClassYear = (e, schoolId, classId) => {
    console.log("edit class year", { classId }, e.target.value)
    updateClass(schoolId, classId, "year", e.target.value)
  }

  const handleSchoolEdit = (schoolId) => {
    console.log("edit school", { schoolId })
    setCurrentSchoolEdit(schoolId)
  }

  const handleStudentDelete = ({ schoolId, classId, studentId }) => {
    console.log("delete student", { studentId })
    const confirmed = isBrowser
      ? window.confirm(
          "Möchten Sie wirklich das Schülerprofil und alle gespeicherten Lernerfolge löschen?"
        )
      : false

    if (confirmed) {
      console.log("delete!")
      deleteStudentMutation.mutate({ id: studentId })
      removeStudent(schoolId, classId, studentId)
    }
  }

  const handleClassDelete = ({ schoolId, classId }) => {
    console.log("delete class", { classId })
    const confirmed = isBrowser
      ? window.confirm(
          "🧨 💣 ☠️ \n\nMöchten Sie diese Klasse wirklich löschen?"
        )
      : false

    if (confirmed) {
      console.log("delete!")
      deleteClassMutation.mutate({ id: classId })
      removeClass(schoolId, classId)
    }
  }

  const handleChangeClassName = ({ e, schoolId, classId }) => {
    updateClass(schoolId, classId, "name", e.target.value)
  }

  const handleChangeSchoolName = ({ e, schoolId }) => {
    updateSchool(schoolId, "name", e.target.value)
  }

  const handleChangeStudentName = ({ e, schoolId, classId, studentId }) => {
    updateStudent(schoolId, classId, studentId, "name", e.target.value)
  }

  const handleStudentBeginnerLvlSelect = ({
    e,
    schoolId,
    classId,
    studentId,
  }) => {
    updateStudent(
      schoolId,
      classId,
      studentId,
      "beginnerLevel",
      parseInt(e.target.value)
    )
  }

  const handleStudentDailyTargetSelect = ({
    e,
    schoolId,
    classId,
    studentId,
  }) => {
    updateStudent(schoolId, classId, studentId, "dailyTarget", e.target.value)
  }

  const handleStudentSelectAllowedSelect = ({
    e,
    schoolId,
    classId,
    studentId,
  }) => {
    updateStudent(
      schoolId,
      classId,
      studentId,
      "selectionAllowed",
      e.target.checked
    )
  }

  const handleGlobalBeginnerLvlSelect = (e) => {
    console.log("selected beginner level: ", e.target.value)
    setGlobalBeginnerLvl(parseInt(e.target.value))
  }

  const handleGlobalDailyTargetSelect = (e) => {
    setGlobalDailyTarget(e.target.value)
  }

  const handleClassGraphemeSelection = (e, graphemeKey) => {
    setClassGraphemesDraft((prev) => ({
      ...prev,
      [graphemeKey]: e.target.value,
    }))
  }

  const handleGlobalSelectionAllowedSelect = (e) => {
    setGlobalSelectionAllowed(e.target.checked)
  }

  const handleChangeClassSave = async ({ schoolId, classId }) => {
    console.log("save class", { classId })
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    const schoolClass = school.classesBySchool.nodes.find(
      (c) => c.id === classId
    )
    const newClassName = schoolClass.name

    await Promise.all(
      schoolClass.studentsByClass.nodes.map(async (student) => {
        const shouldProficiencyUpdate =
          !student.proficiency || student.proficiency < schoolClass.year

        await updateStudentMutation.mutateAsync({
          id: student.id,
          name: student.name,
          proficiency: shouldProficiencyUpdate ? schoolClass.year : null,
          beginnerLevel: student.beginnerLevel,
          dailyTarget: student.dailyTarget,
          graphemeSelection: student.graphemeSelection,
          selectionAllowed: student.selectionAllowed,
          prioWords: student.prioWords,
        })
      })
    )

    await updateClassMutation.mutateAsync({
      id: classId,
      name: newClassName,
      year: schoolClass.year,
      prioWords: schoolClass.prioWords,
    })

    const changeSaveActivity = new ChangeSettingsActivity(
      urlPath,
      TEACHER_SETTINGS.CHANGES_SAVED,
      teacher.id,
      schoolClass.id
    )
    changeSaveActivity.writeToDB("Class")

    setCurrentClassEdit(null)
    setCurrentClassEditSchool(null)
    if (creatingNewClass) setCreatingNewClass(false)
  }

  const handleChangeSchoolNameSave = ({ e, schoolId }) => {
    const school = teacher.schoolsByContact.nodes.find(
      (school) => school.id === schoolId
    )
    updateSchoolNameMutation.mutate({ id: schoolId, name: school.name })
    setCurrentSchoolEdit(null)
  }

  useEffect(() => {
    // authenticate against database
    if ((!jwt || isJWTExpired(jwt)) && isBrowser) navigate("/teacher")
    else if (jwt) {
      graphqlClient.setHeader("authorization", `Bearer ${jwt}`)
      setIsAuthenticated(true)
    }

    return async () => {
      if (isBrowser) {
        window.removeEventListener(
          isMobileSafari ? "pagehide" : "beforeunload",
          logPageLeaving
        )
      }
      console.log("will logout: ", willLogout.current)
      if (!willLogout.current) {
        await logPageLeaving()
      }
    }
  }, [])

  useEffect(() => {
    if (teacher.id && isBrowser) {
      window.addEventListener(
        isMobileSafari ? "pagehide" : "beforeunload",
        logPageLeaving
      )
    }
  }, [teacher])

  useEffect(() => {
    if (currentClassEdit && currentClassEditSchool) {
      updateAllStudents(
        currentClassEditSchool,
        currentClassEdit,
        "beginnerLevel",
        globalBeginnerLvl
      )
    }
  }, [globalBeginnerLvl])

  useEffect(() => {
    if (currentClassEdit && currentClassEditSchool) {
      updateAllStudents(
        currentClassEditSchool,
        currentClassEdit,
        "dailyTarget",
        globalDailyTarget
      )
    }
  }, [globalDailyTarget])

  useEffect(() => {
    if (currentClassEdit && currentClassEditSchool) {
      updateAllStudents(
        currentClassEditSchool,
        currentClassEdit,
        "selectionAllowed",
        globalSelectionAllowed
      )
    }
  }, [globalSelectionAllowed])

  return (
    <Layout layoutHeight={height}>
      <ToastContainer theme="colored" autoClose={3000} />
      <Seo title="Profil" />
      <ProfileLayout backLinkTarget="/">
        {teacher.id && (
          <>
            <Modal
              isOpen={classGraphemeSelectOpen}
              toggle={() => setClassGraphemeSelectOpen((prev) => !prev)}
              modalClassName="grapheme-selection"
              centered
            >
              <ModalHeader
                toggle={() => setClassGraphemeSelectOpen((prev) => !prev)}
              >
                Grapheme für die gesammte Klasse wählen
              </ModalHeader>
              <ModalBody className="body">
                <FormGroup className="grapheme-lvl-form">
                  <Label for={`grapheme-lvl-select all`}>Alle:</Label>
                  <Input
                    type="select"
                    id={`grapheme-lvl-select all`}
                    onChange={(e) => {
                      Object.keys(classGraphemesDraft).forEach((key) => {
                        handleClassGraphemeSelection(e, key)
                      })
                    }}
                  >
                    <option value={"0"}>0 - gar nicht</option>
                    <option value={"1"}>1 - einfach</option>
                    <option value={"2"}>2 - fortgeschritten</option>
                  </Input>
                </FormGroup>
                <div className="grapheme-list">
                  {Object.entries(classGraphemesDraft).map(
                    ([key, value], index) => (
                      <FormGroup className="grapheme-lvl-form">
                        <Label for={`grapheme-lvl-select ${index}`}>
                          {key}:
                        </Label>
                        <Input
                          type="select"
                          id={`grapheme-lvl-select ${index}`}
                          value={value}
                          onChange={(e) => handleClassGraphemeSelection(e, key)}
                        >
                          <option value={"0"}>0 - gar nicht</option>
                          <option value={"1"}>1 - einfach</option>
                          <option value={"2"}>2 - fortgeschritten</option>
                        </Input>
                      </FormGroup>
                    )
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="button confirm"
                  onClick={() => {
                    updateAllStudents(
                      classGraphemeSelectPayload.schoolId,
                      classGraphemeSelectPayload.classId,
                      "graphemeSelection",
                      { ...classGraphemesDraft }
                    )
                    setClassGraphemeSelectOpen(false)
                  }}
                >
                  Bestätigen
                </button>
                <button
                  className="button cancel"
                  onClick={() => {
                    setClassGraphemesDraft({ ...GRAPHEMES_DEFAULT })
                    setClassGraphemeSelectOpen(false)
                  }}
                >
                  Abbrechen
                </button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={studentGraphemeSelectOpen}
              toggle={() => setStudentGraphemeSelectOpen((prev) => !prev)}
              modalClassName="grapheme-selection"
              centered
            >
              <ModalHeader
                toggle={() => setStudentGraphemeSelectOpen((prev) => !prev)}
              >
                Grapheme für {studentGraphemeSelectPayload.studentName} wählen
              </ModalHeader>
              <ModalBody className="body">
                <FormGroup className="grapheme-lvl-form">
                  <Label for={`grapheme-lvl-select all`}>Alle:</Label>
                  <Input
                    type="select"
                    id={`grapheme-lvl-select all`}
                    onChange={(e) => {
                      setStudentGraphemesDraft(
                        produce((draft) => {
                          Object.keys(draft).forEach((key) => {
                            draft[key] = e.target.value
                          })
                        })
                      )
                    }}
                  >
                    <option value={"0"}>0 - gar nicht</option>
                    <option value={"1"}>1 - einfach</option>
                    <option value={"2"}>2 - fortgeschritten</option>
                  </Input>
                </FormGroup>
                <div className="grapheme-list">
                  {Object.entries(studentGraphemesDraft).map(
                    ([key, value], index) => (
                      <FormGroup className="grapheme-lvl-form">
                        <Label for={`grapheme-lvl-select ${index}`}>
                          {key}:
                        </Label>
                        <Input
                          type="select"
                          id={`grapheme-lvl-select ${index}`}
                          value={value}
                          onChange={(e) => {
                            setStudentGraphemesDraft((prev) => ({
                              ...prev,
                              [key]: e.target.value,
                            }))
                          }}
                        >
                          <option value={"0"}>0 - gar nicht</option>
                          <option value={"1"}>1 - einfach</option>
                          <option value={"2"}>2 - fortgeschritten</option>
                        </Input>
                      </FormGroup>
                    )
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="button confirm"
                  onClick={() => {
                    updateStudent(
                      studentGraphemeSelectPayload.schoolId,
                      studentGraphemeSelectPayload.classId,
                      studentGraphemeSelectPayload.studentId,
                      "graphemeSelection",
                      { ...studentGraphemesDraft }
                    )
                    setStudentGraphemeSelectOpen(false)
                  }}
                >
                  Bestätigen
                </button>
                <button
                  className="button cancel"
                  onClick={() => {
                    setStudentGraphemesDraft({ ...GRAPHEMES_DEFAULT })
                    setStudentGraphemeSelectOpen(false)
                  }}
                >
                  Abbrechen
                </button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={classPrioWordSelectOpen}
              toggle={(e) => {
                e.preventDefault()
                setClassPrioWordSelectOpen((prev) => !prev)
              }}
              modalClassName="prio-word-selection"
              centered
            >
              <ModalHeader
                toggle={(e) => {
                  e.preventDefault()
                  setClassPrioWordSelectOpen((prev) => !prev)
                }}
              >
                Lernwörter für die gesammte Klasse festlegen
              </ModalHeader>
              <ModalBody className="body">
                <div className="prio-word-input-div">
                  <TextInput
                    id="prio-words-input"
                    name="prio-words"
                    placeholder="Wort hinzufügen"
                    type="text"
                    value={classPrioWordInput}
                    handleChange={(e) => {
                      setClassPrioWordInput(e.target.value)
                    }}
                    handleKeypress={async (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        let wordQueryResult = null
                        const alreadyExists =
                          classPrioWordDraft.includes(classPrioWordInput)

                        if (!alreadyExists) {
                          wordQueryResult = await graphqlClient.request(
                            WORD_QUERY,
                            { word: classPrioWordInput }
                          )
                        }

                        console.log("word query result:", wordQueryResult)

                        if (wordQueryResult?.wordByWord) {
                          setClassPrioWordDraft((prev) => [
                            ...prev,
                            classPrioWordInput,
                          ])
                          setClassPrioWordInput("")
                        } else {
                          toast.error(
                            `Das Wort "${classPrioWordInput}" ${
                              alreadyExists
                                ? "wurde bereits eingegeben."
                                : "existiert nicht in der Datenbank."
                            }.`
                          )
                        }
                      }
                    }}
                  />
                  <AddButton
                    onClick={async () => {
                      let wordQueryResult = null
                      const alreadyExists =
                        classPrioWordDraft.includes(classPrioWordInput)

                      if (!alreadyExists) {
                        wordQueryResult = await graphqlClient.request(
                          WORD_QUERY,
                          { word: classPrioWordInput }
                        )
                      }

                      console.log("word query result:", wordQueryResult)

                      if (wordQueryResult?.wordByWord) {
                        setClassPrioWordDraft((prev) => [
                          ...prev,
                          classPrioWordInput,
                        ])
                        setClassPrioWordInput("")
                      } else {
                        toast.error(
                          `Das Wort "${classPrioWordInput}" ${
                            alreadyExists
                              ? "wurde bereits eingegeben."
                              : "existiert nicht in der Datenbank."
                          }.`
                        )
                      }
                    }}
                  />
                  <button
                    onClick={async () => {
                      let wordQueryResult = null
                      try {
                        wordQueryResult = await graphqlClient.request(
                          WORD_QUERY,
                          { word: classPrioWordInput }
                        )
                      } catch (error) {
                        toast.error(
                          `Bei der Überprüfung des Wortes ${classPrioWordInput} ist etwas schiefgelaufen.`
                        )
                        return
                      }

                      console.log("word query result:", wordQueryResult)

                      if (wordQueryResult?.wordByWord) {
                        try {
                          const familyMemberWords = (
                            await Promise.all(
                              wordQueryResult.wordByWord.wordBase.map(
                                async (base) => {
                                  const wordFamilyQueryResult =
                                    await graphqlClient.request(
                                      WORD_FAMILY_QUERY,
                                      { base: base }
                                    )
                                  return wordFamilyQueryResult.getWordFamily
                                }
                              )
                            )
                          )
                            .flat()
                            .filter(
                              (word) => !classPrioWordDraft.includes(word)
                            )
                          const cleanedMemberWords = Array.from(
                            new Set(familyMemberWords)
                          )

                          setClassPrioWordDraft((prev) => [
                            ...prev,
                            ...cleanedMemberWords,
                          ])
                          setClassPrioWordInput("")
                        } catch (error) {
                          toast.error(
                            `Bei der Anfrage der Wortfamilie des Wortes ${classPrioWordInput} ist etwas schiefgelaufen.`
                          )
                          console.error(error)
                        }
                      } else {
                        toast.error(
                          `Das Wort "${classPrioWordInput}" existiert nicht in der Datenbank.`
                        )
                      }
                    }}
                    className="button word-base"
                  >
                    Wortfamilie importieren
                  </button>
                </div>
                <ul className="prio-words-list">
                  {classPrioWordDraft.map((w, wIndex) => (
                    <li>
                      {w}
                      <DeleteButton
                        onClick={(e) => {
                          updateClass(
                            classPrioWordSelectPayload.schoolId,
                            classPrioWordSelectPayload.classId,
                            "prioWords",
                            [
                              ...classPrioWordDraft.slice(0, wIndex),
                              ...classPrioWordDraft.slice(
                                wIndex + 1,
                                classPrioWordDraft.length
                              ),
                            ]
                          )
                        }}
                      />
                      ,
                    </li>
                  ))}
                </ul>
              </ModalBody>
              <ModalFooter>
                <button
                  className="button confirm"
                  onClick={(e) => {
                    e.preventDefault()
                    updateAllStudents(
                      classPrioWordSelectPayload.schoolId,
                      classPrioWordSelectPayload.classId,
                      "prioWords",
                      [...classPrioWordDraft]
                    )
                    setClassPrioWordSelectOpen(false)
                  }}
                >
                  Bestätigen
                </button>
                <button
                  className="button cancel"
                  onClick={(e) => {
                    e.preventDefault()
                    setClassPrioWordDraft([])
                    setClassPrioWordSelectOpen(false)
                  }}
                >
                  Abbrechen
                </button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={studentPrioWordSelectOpen}
              toggle={(e) => {
                e.preventDefault()
                setStudentPrioWordSelectOpen((prev) => !prev)
              }}
              modalClassName="prio-word-selection"
              centered
            >
              <ModalHeader
                toggle={(e) => {
                  e.preventDefault()
                  setStudentPrioWordSelectOpen((prev) => !prev)
                }}
              >
                {`Lernwörter für ${studentPrioWordSelectPayload.studentName}` +
                  (currentClassEdit === studentPrioWordSelectPayload.classId
                    ? " festlegen"
                    : "")}
              </ModalHeader>
              <ModalBody className="body">
                {currentClassEdit === studentPrioWordSelectPayload.classId && (
                  <div className="prio-word-input-div">
                    <TextInput
                      id="prio-words-input"
                      name="prio-words"
                      placeholder="Wort hinzufügen"
                      type="text"
                      value={studentPrioWordInput}
                      handleChange={(e) => {
                        setStudentPrioWordInput(e.target.value)
                      }}
                      handleKeypress={async (e) => {
                        if (e.key === "Enter") {
                          e.preventDefault()
                          let wordQueryResult = null

                          const alreadyExists =
                            studentPrioWordDraft.includes(studentPrioWordInput)

                          if (!alreadyExists) {
                            wordQueryResult = await graphqlClient.request(
                              WORD_QUERY,
                              { word: studentPrioWordInput }
                            )
                          }

                          console.log("word query result:", wordQueryResult)

                          if (wordQueryResult?.wordByWord) {
                            setStudentPrioWordDraft((prev) => [
                              ...prev,
                              studentPrioWordInput,
                            ])
                            setStudentPrioWordInput("")
                          } else {
                            toast.error(
                              `Das Wort "${studentPrioWordInput}" ${
                                alreadyExists
                                  ? "wurde bereits eingegeben"
                                  : "existiert nicht in der Datenbank"
                              }.`
                            )
                          }
                        }
                      }}
                    />
                    <AddButton
                      onClick={async () => {
                        let wordQueryResult = null

                        const alreadyExists =
                          studentPrioWordDraft.includes(studentPrioWordInput)

                        if (!alreadyExists) {
                          wordQueryResult = await graphqlClient.request(
                            WORD_QUERY,
                            { word: studentPrioWordInput }
                          )
                        }

                        console.log("word query result:", wordQueryResult)

                        if (wordQueryResult?.wordByWord) {
                          setStudentPrioWordDraft((prev) => [
                            ...prev,
                            studentPrioWordInput,
                          ])
                          setStudentPrioWordInput("")
                        } else {
                          toast.error(
                            `Das Wort "${studentPrioWordInput}" ${
                              alreadyExists
                                ? "wurde bereits eingegeben"
                                : "existiert nicht in der Datenbank"
                            }.`
                          )
                        }
                      }}
                    />
                    <button
                      onClick={async () => {
                        let wordQueryResult = null
                        try {
                          wordQueryResult = await graphqlClient.request(
                            WORD_QUERY,
                            { word: studentPrioWordInput }
                          )
                        } catch (error) {
                          toast.error(
                            `Bei der Überprüfung des Wortes ${studentPrioWordInput} ist etwas schiefgelaufen.`
                          )
                          return
                        }

                        console.log("word query result:", wordQueryResult)

                        if (wordQueryResult?.wordByWord) {
                          try {
                            const familyMemberWords = (
                              await Promise.all(
                                wordQueryResult.wordByWord.wordBase.map(
                                  async (base) => {
                                    const wordFamilyQueryResult =
                                      await graphqlClient.request(
                                        WORD_FAMILY_QUERY,
                                        { base: base }
                                      )
                                    return wordFamilyQueryResult.getWordFamily
                                  }
                                )
                              )
                            )
                              .flat()
                              .filter(
                                (word) => !studentPrioWordDraft.includes(word)
                              )
                            const cleanedMemberWords = Array.from(
                              new Set(familyMemberWords)
                            )

                            setStudentPrioWordDraft((prev) => [
                              ...prev,
                              ...cleanedMemberWords,
                            ])
                            setStudentPrioWordInput("")
                          } catch (error) {
                            toast.error(
                              `Bei der Anfrage der Wortfamilie des Wortes ${studentPrioWordInput} ist etwas schiefgelaufen.`
                            )
                            console.error(error)
                          }
                        } else {
                          toast.error(
                            `Das Wort "${studentPrioWordInput}" existiert nicht in der Datenbank.`
                          )
                        }
                      }}
                      className="button word-base"
                    >
                      Wortfamilie importieren
                    </button>
                  </div>
                )}
                <ul className="prio-words-list">
                  {studentPrioWordDraft.map((w, wIndex) => (
                    <li>
                      {w}
                      {currentClassEdit ===
                        studentPrioWordSelectPayload.classId && (
                        <DeleteButton
                          onClick={(e) => {
                            setStudentPrioWordDraft((prev) => [
                              ...prev.slice(0, wIndex),
                              ...prev.slice(wIndex + 1, prev.length),
                            ])
                          }}
                        />
                      )}
                      ,
                    </li>
                  ))}
                </ul>
              </ModalBody>
              {currentClassEdit === studentPrioWordSelectPayload.classId && (
                <ModalFooter>
                  <button
                    className="button confirm"
                    onClick={(e) => {
                      e.preventDefault()
                      updateStudent(
                        studentPrioWordSelectPayload.schoolId,
                        studentPrioWordSelectPayload.classId,
                        studentPrioWordSelectPayload.studentId,
                        "prioWords",
                        [...studentPrioWordDraft]
                      )
                      setStudentPrioWordSelectOpen(false)
                    }}
                  >
                    Bestätigen
                  </button>
                  <button
                    className="button cancel"
                    onClick={(e) => {
                      e.preventDefault()
                      setStudentPrioWordDraft([])
                      setStudentPrioWordSelectOpen(false)
                    }}
                  >
                    Abbrechen
                  </button>
                </ModalFooter>
              )}
            </Modal>
            <Tali
              size={(width / 800) * 0.8}
              mood="happy"
              position="top"
              float={false}
              messageStyle="glow"
            >
              <p className="tali-question">
                Willkommen in Ihrem Profil, {teacher.name}!<br />
                Hier können Sie Ihre persönlichen Daten verwalten und
                Schulklassen administrieren.
              </p>
            </Tali>
            <h3>Persönliche Daten</h3>
            {teacherEditMode ? (
              <form
                onSubmit={(e) => {
                  handleSave(e)
                  setTeacherEditMode(false)
                }}
              >
                <TextInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-name"
                  name="name"
                  label="Name"
                  required
                  placeholder="Ihr Name"
                  type="text"
                  value={teacher.name}
                />
                <TextInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-email"
                  name="email"
                  label="E-Mail"
                  required
                  placeholder="Ihre E-Mailadresse"
                  type="email"
                  value={teacher.email}
                />
                <PasswordInput
                  handleChange={handleChange}
                  handleKeypress={handleTeacherEditKeypress}
                  id="teacher-password"
                  name="password"
                  label="Passwort ändern"
                  placeholder="Neues Passwort"
                  value={teacher.password}
                />
                <br />
                <button
                  style={{ width: "14rem" }}
                  className="continue-button button button--medium"
                  type="submit"
                >
                  Speichern
                </button>
              </form>
            ) : (
              <>
                <p>Name: {teacher.name}</p>
                <p>E-Mail: {teacher.email}</p>
                <button
                  style={{ width: "min-content" }}
                  className="continue-button button button--medium"
                  onClick={() => setTeacherEditMode(true)}
                >
                  Bearbeiten
                </button>
              </>
            )}
            <h2 style={{ marginTop: "5vh" }}>Schulklassen</h2>
            {teacher.schoolsByContact.nodes.map((school, schoolIndex) => {
              return (
                <div key={school.id}>
                  {currentSchoolEdit === school.id ? (
                    <form
                      onSubmit={(e) =>
                        handleChangeSchoolNameSave({
                          e,
                          schoolId: school.id,
                        })
                      }
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        marginBottom: "1rem",
                      }}
                    >
                      <TextInput
                        handleChange={(e) =>
                          handleChangeSchoolName({
                            e,
                            schoolId: school.id,
                          })
                        }
                        handleKeypress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault()
                            handleChangeSchoolNameSave({
                              e,
                              schoolId: school.id,
                            })
                          }
                        }}
                        id="school-name"
                        name="school-name"
                        label="Name der Schule"
                        required
                        placeholder="Name der Schule"
                        type="text"
                        value={school.name}
                      />
                      <br />
                      <button
                        style={{ width: "min-content", marginLeft: "1rem" }}
                        className="continue-button button button--medium"
                        type="submit"
                      >
                        Speichern
                      </button>
                    </form>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                      }}
                    >
                      <h4>Schule: {school.name}</h4>
                      <EditButton
                        style={{
                          marginLeft: "1rem",
                        }}
                        onClick={() => handleSchoolEdit(school.id)}
                        tooltipText="Den Namen der Schule bearbeiten."
                        position="right"
                      />
                    </div>
                  )}
                  {school.classesBySchool.nodes.map((c, cIndex) => {
                    return (
                      <details
                        key={c.id}
                        open={creatingNewClass && currentClassEdit === c.id}
                      >
                        <summary>Klasse: {c.name}</summary>
                        <div>
                          {currentClassEdit === c.id ? (
                            <form
                              onSubmit={(e) => {
                                e.preventDefault()
                                handleChangeClassSave({
                                  schoolId: school.id,
                                  classId: c.id,
                                })
                              }}
                              className="class-data-edit"
                            >
                              <div className="data">
                                <TextInput
                                  handleChange={(e) =>
                                    handleChangeClassName({
                                      e,
                                      schoolId: school.id,
                                      classId: c.id,
                                    })
                                  }
                                  handleKeypress={(e) =>
                                    handleClassEditKeypress({
                                      e: e,
                                      schoolId: school.id,
                                      classId: c.id,
                                    })
                                  }
                                  id="class-name"
                                  name="class-name"
                                  label="Name der Klasse"
                                  required
                                  placeholder="Name der Klasse"
                                  type="text"
                                  value={c.name}
                                />
                                <NumberInput
                                  id="year"
                                  label="Klassenstufe"
                                  handleChange={(e) =>
                                    handleChangeClassYear(e, school.id, c.id)
                                  }
                                  min={1}
                                  max={5}
                                  value={c.year || 1}
                                  small
                                />
                                {/* <div className="prio-words-div">
                                  <TextInput
                                    id="prio-words-input"
                                    name="prio-words"
                                    label="Lernwörter"
                                    placeholder="Wort hinzufügen"
                                    type="text"
                                    value={prioWordsInput}
                                    handleChange={(e) => {
                                      setPrioWordsInput(e.target.value)
                                    }}
                                    handleKeypress={async (e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault()

                                        const wordQueryResult =
                                          await graphqlClient.request(
                                            WORD_QUERY,
                                            { word: prioWordsInput }
                                          )

                                        console.log(
                                          "word query result:",
                                          wordQueryResult
                                        )

                                        if (wordQueryResult.wordByWord) {
                                          updateClass(
                                            school.id,
                                            c.id,
                                            "prioWords",
                                            [...c.prioWords, prioWordsInput]
                                          )
                                          setPrioWordsInput("")
                                        } else {
                                          toast.error(
                                            `Das Wort "${prioWordsInput}" existiert nicht in der Datenbank.`
                                          )
                                        }
                                      }
                                    }}
                                    handleFocus={(e) => {
                                      updateClass(
                                        school.id,
                                        c.id,
                                        "showPrioWords",
                                        true
                                      )
                                    }}
                                  />
                                  <ul
                                    className="prio-words-list"
                                    style={{
                                      display: c.showPrioWords
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {c.prioWords.map((w, wIndex) => (
                                      <li>
                                        {w}
                                        <DeleteButton
                                          onClick={(e) => {
                                            updateClass(
                                              school.id,
                                              c.id,
                                              "prioWords",
                                              [
                                                ...c.prioWords.slice(0, wIndex),
                                                ...c.prioWords.slice(
                                                  wIndex + 1,
                                                  c.prioWords.length
                                                ),
                                              ]
                                            )
                                          }}
                                        />
                                      </li>
                                    ))}
                                  </ul>
                                </div> */}
                              </div>
                              <button
                                style={{
                                  width: "min-content",
                                }}
                                className="continue-button button button--medium"
                                type="submit"
                              >
                                Speichern
                              </button>
                            </form>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <EditButton
                                onClick={() =>
                                  handleClassEdit({
                                    classId: c.id,
                                    schoolId: school.id,
                                  })
                                }
                                tooltipText="Die Klasse bearbeiten"
                                position="right bottom"
                              />
                              <PDFDownloadButton
                                style={{ marginLeft: "1rem" }}
                                onClick={() => exportStudents(school.id, c.id)}
                                tooltipText="Die Schüler*innen-Liste dieser Klasse als PDF herunterladen"
                                position="right bottom"
                              />
                              <DashboardButton
                                style={{ marginLeft: "1.5rem" }}
                                onClick={() =>
                                  navigate(`/teacher/dashboard?classId=${c.id}`)
                                }
                                tooltipText="zum Lehrkraft-Dashboard"
                                position="right bottom"
                              />
                            </div>
                          )}

                          {c.studentsByClass.nodes.length > 0 && (
                            <div className="table-container">
                              <div className="nav-container">
                                <button
                                  className={c.activeTab === 0 ? "active" : ""}
                                  onClick={() =>
                                    setActiveClassTab(school.id, c.id, 0)
                                  }
                                >
                                  Basisaufgabe
                                </button>
                                <button
                                  className={c.activeTab === 1 ? "active" : ""}
                                  onClick={() =>
                                    setActiveClassTab(school.id, c.id, 1)
                                  }
                                >
                                  Lernwörter
                                </button>
                                <button
                                  className={c.activeTab === 2 ? "active" : ""}
                                  onClick={() =>
                                    setActiveClassTab(school.id, c.id, 2)
                                  }
                                >
                                  Spiele
                                </button>
                              </div>
                              <table
                                style={{
                                  width: "100%",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Login</th>
                                    <th>Alter</th>
                                    {c.activeTab === 0 && <th>Tastatur</th>}
                                    {c.activeTab === 0 && <th>Tagesziel</th>}
                                    {c.activeTab === 1 && <th>Lernwörter</th>}
                                    {/* {c.activeTab === 1 && (
                                      <th>
                                        Graphem-Set durch Kind editierbar?
                                      </th>
                                    )} */}
                                    {c.activeTab === 2 && (
                                      <th>Anzahl ausgewählter Grapheme</th>
                                    )}
                                    {c.activeTab === 2 && (
                                      <th>
                                        Graphem-Set durch Kind editierbar?
                                      </th>
                                    )}
                                    {currentClassEdit === c.id && <th></th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {currentClassEdit === c.id && (
                                    <>
                                      <tr className="class-row">
                                        <td colSpan={3}>Klasse {c.name}</td>
                                        {c.activeTab === 0 && (
                                          <td>
                                            <FormGroup className="select-form">
                                              <Input
                                                type="select"
                                                id="begin-lvl-select"
                                                value={globalBeginnerLvl}
                                                onChange={
                                                  handleGlobalBeginnerLvlSelect
                                                }
                                              >
                                                <option value={0}>
                                                  normal
                                                </option>
                                                <option value={1}>
                                                  Anlaut mit Hilfe
                                                </option>
                                                <option value={2}>
                                                  {
                                                    "Anlaut ohne Hilfe (einfache Wörter)"
                                                  }
                                                </option>
                                                <option value={3}>
                                                  {
                                                    "Anlaut ohne Hilfe (schwierigere Wörter)"
                                                  }
                                                </option>
                                                <option value={4}>
                                                  normal mit Anlaut
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </td>
                                        )}
                                        {c.activeTab === 0 && (
                                          <td>
                                            <NumberInput
                                              id="daily-target-global"
                                              handleChange={
                                                handleGlobalDailyTargetSelect
                                              }
                                              min={10}
                                              max={30}
                                              value={globalDailyTarget}
                                              small
                                            />
                                          </td>
                                        )}
                                        {c.activeTab === 1 && (
                                          <td>
                                            <button
                                              className="button prio-word-select"
                                              onClick={() => {
                                                setClassPrioWordSelectPayload({
                                                  schoolId: school.id,
                                                  classId: c.id,
                                                })
                                                setClassPrioWordDraft([])
                                                setClassPrioWordSelectOpen(true)
                                              }}
                                            >
                                              Lernwörter auswählen
                                            </button>
                                          </td>
                                        )}
                                        {c.activeTab === 2 && (
                                          <td>
                                            <button
                                              className="button grapheme-select"
                                              onClick={() => {
                                                setClassGraphemeSelectPayload({
                                                  schoolId: school.id,
                                                  classId: c.id,
                                                })
                                                setClassGraphemesDraft({
                                                  ...GRAPHEMES_DEFAULT,
                                                })
                                                setClassGraphemeSelectOpen(true)
                                              }}
                                            >
                                              Grapheme auswählen
                                            </button>
                                          </td>
                                        )}
                                        {c.activeTab === 2 && (
                                          <td>
                                            <FormGroup className="checkbox-form">
                                              <Input
                                                type="checkbox"
                                                id={`selection-allowed-checkbox-${c.name}`}
                                                checked={globalSelectionAllowed}
                                                onChange={
                                                  handleGlobalSelectionAllowedSelect
                                                }
                                              />
                                            </FormGroup>
                                          </td>
                                        )}
                                        <td></td>
                                      </tr>
                                      <tr className="empty-row">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </>
                                  )}
                                  {c.studentsByClass.nodes.map(
                                    (student, sIndex) => {
                                      return (
                                        <tr key={student.id}>
                                          <td>
                                            {currentClassEdit === c.id ? (
                                              <form>
                                                <TextInput
                                                  handleChange={(e) =>
                                                    handleChangeStudentName({
                                                      e,
                                                      schoolId: school.id,
                                                      classId: c.id,
                                                      studentId: student.id,
                                                    })
                                                  }
                                                  handleKeypress={(e) => {
                                                    console.log(
                                                      "school:",
                                                      school
                                                    )
                                                    handleClassEditKeypress({
                                                      e,
                                                      schoolId: school.id,
                                                      classId: c.id,
                                                    })
                                                  }}
                                                  id="student-name"
                                                  name="student-name"
                                                  label=""
                                                  required
                                                  placeholder="Schüler Name"
                                                  type="text"
                                                  value={student.name}
                                                />
                                              </form>
                                            ) : (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "70%",
                                                }}
                                              >
                                                {student.name || "-"}
                                              </div>
                                            )}
                                          </td>
                                          <td>{student.login}</td>
                                          <td>{student.age || "-"}</td>
                                          {c.activeTab === 0 && (
                                            <td>
                                              {currentClassEdit === c.id ? (
                                                <FormGroup className="select-form">
                                                  <Input
                                                    type="select"
                                                    id="begin-lvl-select"
                                                    value={
                                                      student.beginnerLevel
                                                    }
                                                    onChange={(e) => {
                                                      handleStudentBeginnerLvlSelect(
                                                        {
                                                          e,
                                                          schoolId: school.id,
                                                          classId: c.id,
                                                          studentId: student.id,
                                                        }
                                                      )
                                                    }}
                                                  >
                                                    <option value={0}>
                                                      normal
                                                    </option>
                                                    <option value={1}>
                                                      Anlaut mit Hilfe
                                                    </option>
                                                    <option value={2}>
                                                      {
                                                        "Anlaut ohne Hilfe (einfache Wörter)"
                                                      }
                                                    </option>
                                                    <option value={3}>
                                                      {
                                                        "Anlaut ohne Hilfe (schwierigere Wörter)"
                                                      }
                                                    </option>
                                                    <option value={4}>
                                                      normal mit Anlaut
                                                    </option>
                                                  </Input>
                                                </FormGroup>
                                              ) : (
                                                [
                                                  "Anlaut mit Hilfe",
                                                  "Anlaut ohne Hilfe (einfache Wörter)",
                                                  "Anlaut ohne Hilfe (schwierigere Wörter)",
                                                ][student.beginnerLevel - 1] ||
                                                "normal"
                                              )}
                                            </td>
                                          )}
                                          {c.activeTab === 0 && (
                                            <td>
                                              {currentClassEdit === c.id ? (
                                                <NumberInput
                                                  id={`daily-target-${sIndex}`}
                                                  handleChange={(e) =>
                                                    handleStudentDailyTargetSelect(
                                                      {
                                                        e,
                                                        schoolId: school.id,
                                                        classId: c.id,
                                                        studentId: student.id,
                                                      }
                                                    )
                                                  }
                                                  min={10}
                                                  max={30}
                                                  value={student.dailyTarget}
                                                  small
                                                />
                                              ) : (
                                                student.dailyTarget ?? "-"
                                              )}
                                            </td>
                                          )}
                                          {c.activeTab === 1 && (
                                            <td>
                                              <button
                                                className="button prio-word-select"
                                                onClick={() => {
                                                  setStudentPrioWordSelectPayload(
                                                    {
                                                      schoolId: school.id,
                                                      classId: c.id,
                                                      studentId: student.id,
                                                      studentName: student.name,
                                                    }
                                                  )
                                                  setStudentPrioWordDraft([
                                                    ...student.prioWords,
                                                  ])
                                                  setStudentPrioWordSelectOpen(
                                                    true
                                                  )
                                                }}
                                              >
                                                Lernwörter{" "}
                                                {currentClassEdit === c.id
                                                  ? "auswählen"
                                                  : "anzeigen"}
                                              </button>
                                            </td>
                                          )}
                                          {c.activeTab === 2 && (
                                            <td>
                                              {currentClassEdit === c.id ? (
                                                <button
                                                  className="button grapheme-select"
                                                  onClick={() => {
                                                    setStudentGraphemeSelectPayload(
                                                      {
                                                        schoolId: school.id,
                                                        classId: c.id,
                                                        studentId: student.id,
                                                        studentName:
                                                          student.name,
                                                      }
                                                    )
                                                    setStudentGraphemesDraft({
                                                      ...student.graphemeSelection,
                                                    })
                                                    setStudentGraphemeSelectOpen(
                                                      true
                                                    )
                                                  }}
                                                >
                                                  Grapheme auswählen
                                                </button>
                                              ) : (
                                                Object.values(
                                                  student.graphemeSelection
                                                ).filter(
                                                  (level) => level !== "0"
                                                ).length ?? "-"
                                              )}
                                            </td>
                                          )}
                                          {c.activeTab === 2 && (
                                            <td>
                                              {currentClassEdit === c.id ? (
                                                <FormGroup className="checkbox-form">
                                                  <Input
                                                    type="checkbox"
                                                    id={`selection-allowed-checkbox-${schoolIndex}${cIndex}${sIndex}`}
                                                    checked={
                                                      student.selectionAllowed
                                                    }
                                                    onChange={(e) => {
                                                      handleStudentSelectAllowedSelect(
                                                        {
                                                          e,
                                                          schoolId: school.id,
                                                          classId: c.id,
                                                          studentId: student.id,
                                                        }
                                                      )
                                                    }}
                                                  ></Input>
                                                </FormGroup>
                                              ) : student.selectionAllowed ? (
                                                "Ja"
                                              ) : (
                                                "Nein"
                                              )}
                                            </td>
                                          )}
                                          {currentClassEdit === c.id && (
                                            <td>
                                              <DeleteButton
                                                onClick={() =>
                                                  handleStudentDelete({
                                                    schoolId: school.id,
                                                    classId: c.id,
                                                    studentId: student.id,
                                                  })
                                                }
                                                tooltipText={`Das Kind "${
                                                  student.alias ||
                                                  student.name ||
                                                  ""
                                                }" löschen`}
                                                position="top left"
                                              />
                                            </td>
                                          )}
                                        </tr>
                                      )
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "1vh",
                            }}
                          >
                            <span>Schüler*innen hinzufügen</span>
                            <AddButton
                              style={{
                                marginLeft: "1rem",
                              }}
                              onClick={() => handleStudentsAdd(school.id, c.id)}
                            />
                          </div>
                          {currentClassEdit === c.id && (
                            <div style={{ display: "flex", marginTop: "1em" }}>
                              <span>Die Klasse löschen: </span>
                              <DeleteButton
                                style={{
                                  marginLeft: "1em",
                                }}
                                onClick={() =>
                                  handleClassDelete({
                                    schoolId: school.id,
                                    classId: c.id,
                                  })
                                }
                                tooltipText="Die Klasse löschen"
                                position="top right"
                              />
                            </div>
                          )}
                        </div>
                      </details>
                    )
                  })}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "1vh",
                      marginTop: "4vh",
                    }}
                  >
                    <span>Klasse hinzufügen</span>
                    <AddButton
                      style={{
                        marginLeft: "1rem",
                      }}
                      onClick={() => handleClassAdd(school.id)}
                    />
                  </div>
                </div>
              )
            })}
            <br />
            <div style={{ display: "flex", gap: "5rem", marginTop: "5rem" }}>
              <button
                className="continue-button button button--medium logout"
                onClick={() => logout()}
              >
                Abmelden
              </button>
              <button
                className="danger-button button--medium"
                onClick={() => deleteAccount()}
              >
                Profil löschen
              </button>
            </div>
          </>
        )}
      </ProfileLayout>
    </Layout>
  )
}

export default ProfilePage
