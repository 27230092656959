import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { fluidRange } from "polished"

import { createColorGradient } from "../../styles/helpers"
import { BaseLayout } from "../../styles/common"
import { MainButton } from "../../styles/buttons"

import ArrowIcon from "../../lib/icons/Arrow"

const StyledProfileLayout = styled(BaseLayout)`
  padding: 0;
  align-content: stretch;
  display: flex;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .panel {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    color: white;
    background-color: ${({ theme }) => theme.colors.primary.shade};
    box-shadow: 0px 10px 250px #36c7e7;
  }

  .panel-content {
    height: 100%;
    box-sizing: border-box;
    display: block;
    align-content: center;
    justify-content: center;
    padding: ${({ theme }) => theme.spacings.fluid.regular}vw;
    font-size: calc(8px + 1.25vmax);

    & .continue-button,
    & .danger-button {
      font-size: inherit;
    }

    & .danger-button {
      padding-left: 1vmax;
      padding-right: 1vmax;
      padding-bottom: 0.3vmax;
      padding-top: 0.3vmax;
    }

    & details {
      margin-bottom: 1rem;
      border: 3px solid white;
      border-radius: 8px;
      padding: 12px 4px 12px 4px;
      overflow-x: auto;

      & form {
        font-size: calc(8px + 1.25vmax);

        &.class-data-edit {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-items: start;
          gap: 2vw;
          margin-top: 1.5rem;
          margin-bottom: 2rem;
          font-size: inherit;

          & .data {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 2vw;
            font-size: inherit;

            & .prio-words-div {
              /* display: flex;
              flex-direction: column-reverse;
              align-items: flex-start;
              justify-content: flex-start; */

              & .prio-words-list {
                position: relative;
                animation: 0.4s fadeIn linear;

                & li {
                  display: flex;
                  flex-direction: row;
                  gap: 1vw;
                }
                & button {
                  padding-left: 0.2em;
                  padding-right: 0.2em;
                  padding-bottom: 0.2em;
                  padding-top: 0.2em;
                }
              }
            }
          }
        }

        & #class-name,
        & #prio-words-input,
        & select,
        & .input-wrapper .value-display,
        & .continue-button {
          font-size: inherit;
        }

        & select {
          margin-left: 8px;
        }

        & .input-wrapper {
          & button {
            padding-left: 0.5vmax;
            padding-right: 0.5vmax;
            padding-bottom: 0.5vmax;
            padding-top: 0.5vmax;

            & svg {
              width: calc(13px + 1.5vmax);
              height: calc(13px + 1.5vmax);
            }
          }
        }
      }

      summary {
        cursor: pointer;
        user-select: none;
        font-size: calc(10px + 1.5vmax);

        &:hover {
          opacity: 0.7;
        }
      }
    }

    & div.table-container {
      overflow-x: auto;
      display: flex;
      flex-direction: column;
    }

    & div.nav-container {
      display: flex;
      flex-direction: row;
      & button {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        border: 3px solid gray;
        transition: opacity 0.2s, background-color 0.3s, color 0.3s;

        ${({ theme }) =>
          createColorGradient(
            theme.colors.primary.main,
            theme.colors.primary.shade,
            "to left"
          )}
        color: white;

        &.active {
          ${({ theme }) =>
            createColorGradient(
              theme.colors.cta.main,
              theme.colors.cta.shade,
              "to right"
            )}
          border: 3px solid black;
          color: black;
        }

        &:hover {
          opacity: 0.7;
        }

        border-bottom: 6px solid gray;
      }
    }

    & table,
    th,
    td {
      border: 2px solid gray;
      animation: fadeIn 0.6s;
      -webkit-animation: fadeIn 0.6s;
      -moz-animation: fadeIn 0.6s;
      -o-animation: fadeIn 0.6s;
      -ms-animation: fadeIn 0.6s;
    }

    & table {
      table-layout: auto;

      & .empty-row {
        height: 45px;
      }

      & thead th {
        text-align: start;
        font-size: calc(5px + 1vmax);
        text-decoration: underline;
      }

      & tbody tr.class-row {
      }

      & tbody td {
        font-size: calc(9px + 1vmax);

        & form {
          display: flex;
          align-items: center;
        }

        & button.grapheme-select,
        button.prio-word-select {
          padding-left: 0.5vmax;
          padding-right: 0.5vmax;
          padding-bottom: 0.2vmax;
          padding-top: 0.2vmax;
          font-size: calc(8px + 0.8vmax);
          border-radius: 20px;
          width: auto;
          position: relative;
        }

        & .checkbox-form {
          display: flex;
          justify-content: center;

          & .form-check-input {
            font-size: calc(10px + 1.5vmax);
          }
        }

        & form input,
        & .form-select,
        & .input-wrapper .value-display {
          font-size: inherit;
        }

        & .form-select,
        & form input {
          padding-bottom: 0.25vmax;
          padding-top: 0.25vmax;
        }

        & .input-wrapper {
          & button {
            padding-left: 0.5vmax;
            padding-right: 0.5vmax;
            padding-bottom: 0.5vmax;
            padding-top: 0.5vmax;
            margin-left: 0;

            & svg {
              width: calc(13px + 1vmax);
              height: calc(13px + 1vmax);
            }
          }
        }

        & .select-form {
          width: auto;
          & .form-select {
            width: inherit;
            white-space: normal;
          }
        }

        &:last-child > button {
          font-size: calc(7px + 1vmax);
          margin-left: 1vmax;
          padding-left: 0.2em;
          padding-right: 0.2em;
          padding-bottom: 0.2em;
          padding-top: 0.2em;
        }
      }

      tbody:before {
        content: "@";
        display: block;
        line-height: 12px;
        text-indent: -99999px;
      }
    }
  }

  .profile-container {
    margin-bottom: ${({ theme }) => theme.spacings.fluid.medium}vh;
  }

  .actions {
    display: grid;
    gap: ${({ theme }) => theme.spacings.fluid.small}vh;
    justify-items: start;
  }

  .back-button {
    position: absolute;
    left: 5vw;
    top: 10%;
    transform: translate(-50%, -50%);

    ${({ theme }) =>
      fluidRange({
        prop: "font-size",
        fromSize: theme.fontSizes.regular,
        toSize: theme.fontSizes.large,
      })}

    svg {
      transform: rotate(180deg);
    }
  }
`

const ProfileLayout = ({ backLinkTarget, children }) => {
  return (
    <StyledProfileLayout>
      <div className="panel">
        <div className="panel-content">{children}</div>
        <div className="back-button">
          <MainButton as={Link} to={backLinkTarget}>
            <ArrowIcon />
          </MainButton>
        </div>
      </div>
    </StyledProfileLayout>
  )
}

export default ProfileLayout
