import { navigate } from "gatsby"

export const handleJWTtimeout = (error) => {
  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process
  if (error?.response?.errors[0]?.message === "jwt expired" && isBrowser) {
    window.localStorage.removeItem("jwt_teacher")
    window.localStorage.removeItem("jwt_student")
    navigate("/")
  }
}
